<template>
  <router-view></router-view>
  <cookie></cookie>
</template>

<script>
import Cookie from "./components/Cookies";
export default {
  name: "App",
  components: {Cookie}
}
</script>

<style scoped>

</style>