import { createApp } from "vue"
import VueSmoothScroll from 'vue3-smooth-scroll'

import App from "./App";
import router from "./router/router";


const app = createApp(App)
    .use(router)
    .use(VueSmoothScroll)

router.isReady().then(() => {
  app.mount('#app');
});