import {createRouter, createWebHistory } from "vue-router";

const HomePage = () => import('@/pages/HomePage');
const LoginPage = () => import('@/pages/LoginPage');
const RegisterPage = () => import('@/pages/RegisterPage');
const ContatoPage = () => import('@/pages/ContatoPage');
const RecoveryPage = () => import('@/pages/RecoveryPage');
const CadastreSeuEventoPage = () => import('@/pages/CadastreSeuEventoPage');
const NotFoundPage = () => import('@/pages/NotFoundPage');
const MinhaContaPage = () => import('@/pages/MinhaContaPage');
const CalendarioPage = () => import('@/pages/CalendarioPage');
const ConteudoPage = () => import('@/pages/ConteudoPage');
const NewsPage = () => import('@/pages/NewsPage');
const EventoPage = () => import('@/pages/EventoPage');
const RestritoPage = () => import('@/pages/RestritoPage');
const EscolhaKitRestritoPage = () => import('@/pages/EscolhaKitRestritoPage');
const OrganizadorPage = () => import('@/pages/OrganizadorPage');
const AtualizarDadosPage = () => import('@/pages/AtualizarDadosPage');
const VerDetalhesPages = () => import('@/pages/VerDetalhesPages');
const ImpressaoPage = () => import('@/pages/ImpressaoPage');
const EscolhaKitPage = () => import('@/pages/EscolhaKitPage');
const CarrinhoPage = () => import('@/pages/CarrinhoPage');
const InscricaoPage = () => import('@/pages/InscricaoPage');
const PagamentoPage = () => import('@/pages/PagamentoPage');
const SucessoPage = () => import('@/pages/SucessoPage');
const SucessoBoletoPage = () => import('@/pages/SucessoBoletoPage');
const SucessoPixPage = () => import('@/pages/SucessoPixPage');


const routes = [
  { path: '/', component: HomePage,name: 'HomePage', },
  { path: '/acesso-atleta/', component: LoginPage,name: 'LoginPage',meta: {guest: true}   },
  { path: '/cadastre-se/', component: RegisterPage,name: 'RegisterPage',meta: {guest: true}  },
  { path: '/contato/', component: ContatoPage,name: 'ContatoPage',  },
  { path: '/esqueci-minha-senha/', component: RecoveryPage,name: 'RecoveryPage',meta: {guest: true}  },
  { path: '/cadastre-seu-evento/', component: CadastreSeuEventoPage,name: 'CadastreSeuEventoPage',  },
  { path: "/noticia/:id/:slug/", component: NewsPage,name: 'NewsPage', },
  { path: "/noticia/nao-encontrado/", component: NotFoundPage,name: 'NotFoundNewsPage', },
  { path: "/conteudo/:id/:slug/", component: ConteudoPage,name: 'ConteudoPage', },
  { path: '/conteudo/nao-encontrado/', component: NotFoundPage,name: 'NotFoundConteudoPage',  },
  { path: "/evento/:id/:slug/", component: EventoPage,name: 'EventoPage', },
  { path: "/evento/:id/:slug/:hash", component: RestritoPage,name: 'RestritoLotePage', },
  { path: "/evento/:id/:slug/:hash/:lote", component: RestritoPage,name: 'RestritoPage', },
  { path: '/evento/nao-encontrado/', component: NotFoundPage,name: 'NotFoundEventoPage', },
  { path: "/escolha-o-kit/:id/:slug/", component: EscolhaKitPage,name: 'EscolhaKitPage', meta: {requiresAuth: true} },
  { path: "/escolha-o-kit/:id/:slug/:hash", component: EscolhaKitRestritoPage,name: 'EscolhaKitRestritoPage', meta: {requiresAuth: true} },
  { path: "/escolha-o-kit/:id/:slug/:hash/:lote", component: EscolhaKitRestritoPage,name: 'EscolhaKitRestritoLotePage', meta: {requiresAuth: true} },
  { path: "/inscricao/:id/:slug/:hash/:lote", component: InscricaoPage,name: 'InscricaoPageLote' },
  { path: "/inscricao/:id/:slug/:hash", component: InscricaoPage,name: 'InscricaoPageHash' },
  { path: "/inscricao/:id/:slug/", component: InscricaoPage,name: 'InscricaoPage' },
  { path: "/organizador/:id/:slug/", component: OrganizadorPage,name: 'OrganizadorPage', },
  { path: '/organizador/nao-encontrado/', component: NotFoundPage,name: 'NotFoundOrganizadorPage', },
  { path: '/minha-conta/', component: MinhaContaPage,name: 'MinhaContaPage', meta: {requiresAuth: true}},
  { path: '/minha-conta/atualizar-dados/', component: AtualizarDadosPage,name: 'AtualizardadosPage', meta: {requiresAuth: true}},
  { path: '/minha-conta/:id/ver-detalhes/', component: VerDetalhesPages,name: 'VerDetalhesPages', meta: {requiresAuth: true}},
  { path: '/minha-conta/:id/impressao/', component: ImpressaoPage,name: 'ImpressaoPage', meta: {requiresAuth: true}},
  { path: '/calendario/', component: CalendarioPage,name: 'CalendarioPage',  },
  { path: '/carrinho/', component: CarrinhoPage,name: 'CarrinhoPage', meta: {requiresAuth: true} },
  { path: '/pagamento/', component: PagamentoPage,name: 'PagamentoPage', meta: {requiresAuth: true} },
  { path: '/sucesso/', component: SucessoPage,name: 'SucessoPage', meta: {requiresAuth: true} },
  { path: '/sucesso/boleto', component: SucessoBoletoPage,name: 'SucessoBoletoPage', meta: {requiresAuth: true} },
  { path: '/sucesso/pix', component: SucessoPixPage,name: 'SucessoPixPage', meta: {requiresAuth: true} },
  { path: '/:pathMatch(.*)*', component: NotFoundPage,name: 'NotFoundPage',  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('user-token') == null) {
      next({
        path: '/acesso-atleta/',
        params: { nextUrl: to.fullPath }
      })
    } else {
      next()
    }
  } else if(to.matched.some(record => record.meta.guest)) {
    if(localStorage.getItem('user-token') == null){
      next()
    }else{
      next({ name: 'Home',path: '/'})
    }
  }else {
    next()
  }
})

export default router;