<template>
  <div v-if="show" class="full-screen">

    <div class="cookie-notice">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="h3">Este website utiliza cookies</div>
          </div>
          <div class="col-md-12" style="margin-top: 5px;">
            <div>Utilizamos cookies básicos para o melhor funcionamento do sistema</div>
          </div>

          <div class="col-md-12 text-right" style="margin-top: 15px">
            <a class="btn btn-success"  href="#" @click="aceitar_cookie">Aceitar e Continuar</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Cookie",
  data() {
    return {
      show: false,
    };
  },
  methods: {
    aceitar_cookie() {
      window.localStorage.setItem('allow-cookies', true);
      this.show = false;
    }
  },
  mounted() {
    let data = window.localStorage.getItem('allow-cookies')
    this.show = data == null;
  },
};
</script>

<style>
.full-screen {
  width: 100vw;
  height: 100svh;
  position: fixed;
  top: 0;
  right: 0;
  background: #8989896b;
  z-index: 9999999999;
}

.cookie-notice {
  bottom: 0px;
  position: absolute;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100vw;
  background-color: #fff;
}

.cookie-notice .container {
  margin-bottom: 20px;
  margin-top: 10px;

  padding-left: 30px;
  padding-right: 30px;
}
</style>
